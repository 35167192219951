import tw, { css } from 'twin.macro'

export const containerStyles = css`
  ${tw`m-auto fixed right-0 top-0 bottom-0 w-full max-w-[835px]`}

  & > div {
    ${tw`h-full`}
  }
`
export const innerStyles = css`
  ${tw`bg-white h-full w-full overflow-y-auto pl-6 pr-8 lg:[padding-left:110px] lg:[padding-right:89px] py-6 lg:py-8`}
`
