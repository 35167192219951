import { styled } from 'twin.macro'
import type { TTagProps } from './interface'
import baseStyle from './styles'

const StyledTag = styled.div(() => [baseStyle])

const Tag = (props: TTagProps) => {
  return <StyledTag {...props} />
}
export default Tag
