import tw from 'twin.macro'

export const closeBtnStyle = tw`
  absolute xl:top-10 xl:right-20 lg:top-8 lg:right-8 z-10 top-4 right-4 w-[2.5rem]
  h-[2.5rem]
`

export const wrapperStyle = tw`
  relative z-50 top-0 bottom-0 h-full w-full right-0 left-0 bg-[rgba(11, 11, 11, 0.9)] flex items-center justify-center
`
export const containerStyle = tw`
  w-screen h-screen relative flex items-center m-auto justify-center
`
