import { AnimatedTeam } from '@okam/brand-ui/index'
import { extractRelativePath } from '@okam/html2react/index'
import { useIsClient } from '@okam/shared-ui'
import { useTranslation } from 'next-i18next'
import { cluster } from 'radash'
import React from 'react'
import tw from 'twin.macro' /* eslint-disable-line */
import type { AllMembersFragment, Maybe } from 'gql/graphql'
import { MEMBERS_PER_COL, MEMBERS_TYPE_DISPLAY, TEAM_ANIMATION_MEMBERS_COL } from '../../../../const'
import type { TTeamSearchLayout } from '../types'

type AnimatedMember = { image: string; alt: string; url: string }

// if there are less than 5 members per columns, duplicate members to fill the gap
const getMembersPerCol = (members: AnimatedMember[]): AnimatedMember[] => {
  if (!members || members.length >= MEMBERS_PER_COL) {
    return members ?? []
  }

  const duplicatedMembers: AnimatedMember[] = [...members, ...members, ...members]
  return duplicatedMembers
}

const TeamSearchLayout = ({ props, members }: { props: TTeamSearchLayout; members?: Maybe<AllMembersFragment> }) => {
  const { title, subtitle, link } = props

  const columnsNumber = TEAM_ANIMATION_MEMBERS_COL
  const membersTypesToKeep = MEMBERS_TYPE_DISPLAY

  const { t } = useTranslation('common')
  const { isClient } = useIsClient()

  const searchAriaLabel = t('ARIA.SEARCH_MEMBER')
  const searchPlaceholder = t('PLACEHOLDER.SEARCH')

  const animatedMembers: AnimatedMember[] =
    members?.nodes
      .filter((member) =>
        member?.memberTypes?.nodes?.some((memberType) => membersTypesToKeep.includes(memberType.slug ?? '')),
      )
      .sort(() => 0.5 - Math.random())
      .map(({ memberFields, link: memberLink }) => ({
        image: memberFields?.memberAlternativeImage?.sourceUrl ?? '',
        alt: memberFields?.memberAlternativeImage?.altText ?? `Attorney's image`,
        url: memberLink ? extractRelativePath(memberLink) : '',
      }))
      .filter((member) => member.image !== '') ?? []

  if (!animatedMembers.length) return null
  const membersClustered = cluster(animatedMembers, Math.floor(animatedMembers.length / columnsNumber))

  return isClient ? (
    <div tw="bg-tertiary-1">
      <AnimatedTeam
        members={members}
        title={title ?? ''}
        text={subtitle ?? ''}
        linkTitle={link?.title ?? ''}
        linkUrl={link?.url ? extractRelativePath(link.url) : ''}
        searchAriaLabel={searchAriaLabel}
        searchPlaceholder={searchPlaceholder}
        variant="full"
        team={{
          firstColumn: getMembersPerCol(membersClustered[0]),
          secondColumn: getMembersPerCol(membersClustered[1]),
          thirdColumn: getMembersPerCol(membersClustered[2]),
          fourthColumn: getMembersPerCol(membersClustered[3]),
        }}
      />
    </div>
  ) : null
}

export default TeamSearchLayout
