import tw from 'twin.macro'

const baseStyle = tw`
  flex
  items-center
  justify-center
  bg-transparent
  text-black 
  text-button
  font-body
  uppercase
  rounded-full
  min-h-[30px]
  min-w-[30px]
  px-4
  border
  border-gray-300
  hover:bg-tertiary-5
  hover:border-tertiary-5
  hover:text-white
  active:bg-tertiary-4
  active:border-tertiary-4
  active:text-white
  focus-visible:outline-2
  focus-visible:outline-offset-4
  focus-visible:outline-tertiary-5
  disabled:opacity-50
`

export default baseStyle
