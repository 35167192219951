import tw, { css } from 'twin.macro'

export const closeBtnStyle = css`
  ${tw`fixed top-1 right-2 z-10  lg:right-4 lg:top-4 transform scale-75 origin-center bg-gray-1`}
  svg {
    ${tw`transform scale-75 `}
  }
`

export const Div100vhStyle = css`
  ${tw`m-auto fixed right-0 top-0 bottom-0 w-full max-w-[512px]`}

  & > div {
    ${tw`h-full`}
  }
`

export const divWrapperStyle = tw`
  px-6 pt-24 lg:px-12 lg:pt-48
`
