import tw, { css } from 'twin.macro'
import type { TColorTheme } from './interface'

const variants = {
  primary: css`
    & > h3 {
      ${tw`border-b-tertiary-3`}
    }

    &:hover,
    &:active {
      h3 {
        ${tw`text-primary-1`}
      }
      div:last-child {
        ${tw`bg-tertiary-4 text-white`}
      }
    }
  `,
  secondary: css`
    ${tw`text-white`}

    & > h3 {
      ${tw`border-b-secondary-3`}
    }

    &:hover,
    &:active {
      h3 {
        ${tw`text-gray-300`}
      }
      div:last-child {
        ${tw`bg-secondary-1 text-white`}
      }
    }
  `,
  gray: css`
    & > h3 {
      ${tw`border-b-gray-200`}
    }

    &:hover,
    &:active {
      h3 {
        ${tw`text-primary-1`}
      }
      div:last-child {
        ${tw`bg-gray-400`}
      }
    }
  `,
}

const buttonVariants = {
  primary: css`
    ${tw`bg-tertiary-3 text-black`}
  `,
  secondary: css`
    ${tw`bg-secondary-3 text-white`}
  `,
  gray: css`
    ${tw`bg-gray-100`}
  `,
}

export const getCardStyle = (variant: TColorTheme) => {
  return variants[variant]
}

export const getButtonStyle = (variant: TColorTheme) => {
  return buttonVariants[variant]
}
