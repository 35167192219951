import tw, { css } from 'twin.macro'

export const hoverStyles = css`
  img {
    ${tw`transition duration-300 ease-in-out`}
  }

  &:hover,
  &:active {
    img {
      ${tw`scale-105`}
    }

    & + div {
      h3 {
        ${tw`text-primary-1`}
      }

      a {
        ${tw`lg:text-black`}
      }
    }
  }
`
