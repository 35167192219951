// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Grid, Cover } from '@bedrock-layout/primitives'
import type { TLogoBannerProps } from './interface'

const LogoBanner = (props: TLogoBannerProps) => {
  const { children } = props
  return (
    <Cover tw="bg-secondary-2 border-t border-gray-200 px-4" minHeight="fit-content" {...props}>
      <div tw="max-w-[1920px] grid grid-cols-2 md:grid-cols-4 mx-auto px-6 md:px-8 lg:px-10 2xl:px-14 py-4">
        {children}
      </div>
    </Cover>
  )
}

export default LogoBanner
