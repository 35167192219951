/* eslint-disable react-hooks/exhaustive-deps */
import { SidePanel, useSidePanel } from '@okam/shared-ui'
import { useCallback } from 'react'
import { BrandButtonWithForwardRef } from '../BrandButton'
import Heading from '../Heading'
import type { TBrandModalProps } from './interface'
import { containerStyles, innerStyles } from './styles'

const BrandModal = (props: TBrandModalProps) => {
  const { id, children, title } = props
  const { buttonProps } = useSidePanel()
  const { closeButtonProps, closeButtonRef } = buttonProps

  const CloseButton = useCallback(
    () => (
      <BrandButtonWithForwardRef
        variant="gray"
        buttonStyle="default"
        icon="Close"
        {...closeButtonProps}
        ref={closeButtonRef}
        tw="ml-auto !px-0"
        aria-label="Close the current modal"
      />
    ),
    [],
  )

  return (
    <SidePanel id={id} containerStyle={containerStyles} innerDivStyle={innerStyles}>
      <CloseButton />
      <Heading
        variant="h3"
        as="h1"
        tw="w-full font-semibold text-tertiary-5 mt-14 lg:[margin-top:76px] pb-2 border-b border-b-tertiary-2 mb-9"
      >
        {title}
      </Heading>
      {children}
    </SidePanel>
  )
}

export default BrandModal
