/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
import { Cover, Inline } from '@bedrock-layout/primitives'

import { TextColumns, WysiwygBlock, Heading, ModalCTACard, CardsColumns, BrandModal } from '@okam/brand-ui'
import { useHtml2React } from '@okam/html2react/provider'
import { SidePanelContextProvider, Typography } from '@okam/shared-ui'
import tw from 'twin.macro'
import type { TModalCardLayout } from '../types'

export type TModalCardsLayoutStyle = 'style1' | 'style2' | 'style3'
export type TModalCardStyle = 'gray' | 'primary' | 'secondary'

const getModalCardsStyle = {
  style1: {
    section: tw`bg-white text-black`,
    heading: tw`text-tertiary-5`,
    card: 'gray',
  },
  style2: {
    section: tw`bg-secondary-2 text-white`,
    heading: tw`text-white`,
    card: 'secondary',
  },
  style3: {
    section: tw`bg-tertiary-2 text-black`,
    heading: tw`text-tertiary-5`,
    card: 'primary',
  },
}

const Html2React = ({ content }: { content: string }) => {
  const { parseHTMLToReact } = useHtml2React()

  return (
    <Typography as="section" variant="p">
      {parseHTMLToReact(content)}
    </Typography>
  )
}

const ModalCardsLayout = (props: TModalCardLayout) => {
  const { title, content, classname, style, columns, cards } = props
  const MondalCardLayoutStyle = getModalCardsStyle[style as TModalCardsLayoutStyle]

  return (
    <Cover css={[MondalCardLayoutStyle.section, classname ?? '']} minHeight="fit-content">
      <Inline
        justify="center"
        tw="flex flex-col h-full pt-24 pb-10 lg:pt-28 lg:pb-16 xl:pt-32 px-6 mx-auto md:px-12 lg:px-14 w-full max-w-[1920px]"
      >
        <TextColumns
          nbCols={Number(columns)}
          title={
            <Heading as="h2" variant="h2" css={[MondalCardLayoutStyle.heading]}>
              {title}
            </Heading>
          }
        >
          {content && (
            <div>
              <WysiwygBlock content={content} />
            </div>
          )}
          {cards && (
            <div tw="py-8" css={MondalCardLayoutStyle.heading}>
              <CardsColumns nbCols={Number(columns)}>
                {cards.map((card, index: number) => {
                  if (!card?.title) return null
                  return (
                    <SidePanelContextProvider
                      defaultSelectedKey={`modal-card-${title}-${card.title}-${index}`}
                      key={`modal-card-${title}-${card.title}-${index}`}
                    >
                      <ModalCTACard
                        title={card.title}
                        text={<WysiwygBlock content={card?.content ?? ''} as="span" />}
                        colorTheme={MondalCardLayoutStyle.card as TModalCardStyle}
                        ariaLabel={card.title}
                      />
                      <BrandModal title={card.title} id={`brand-modal-card-${title}-${card.title}-${index}`}>
                        <div tw="prose prose-woods">
                          <Html2React content={card?.content2 ?? ''} />
                        </div>
                      </BrandModal>
                    </SidePanelContextProvider>
                  )
                })}
              </CardsColumns>
            </div>
          )}
        </TextColumns>
      </Inline>
    </Cover>
  )
}

export default ModalCardsLayout
