/* eslint-disable no-unused-vars */
import { Cover, Inline } from '@bedrock-layout/primitives'
import { Heading, PageLinkCard, CardLayoutFold, WysiwygBlock } from '@okam/brand-ui/index'
import { extractRelativePath } from '@okam/html2react/index'
import { useTranslation } from 'next-i18next'
import React from 'react'
import tw, { css, type TwStyle } from 'twin.macro'
import type { TCardLayout } from '../types'

const CardLayout = (props: TCardLayout) => {
  const { cards, title, content, columns, style, classname, havethreecards } = props

  const styles: Record<string, TwStyle> = {
    style1: tw`bg-white`,
    style2: tw`bg-tertiary-1`,
    style3: tw`bg-tertiary-2`,
  }

  const { t } = useTranslation(['common'], { useSuspense: true })

  const cardsCtaText = t('LINKS.LEARN')
  const cardsAriaLabel = t('LINKS.ARIA_PAGE')

  return (
    <Cover
      css={[
        css`
          ${classname}
        `,
        styles[style ?? 'style1'],
      ]}
      minHeight="fit-content"
    >
      <Inline
        justify="center"
        tw="flex flex-col h-full pt-24 pb-10 lg:pt-28 lg:pb-16 xl:pt-32 px-6 mx-auto md:px-12 lg:px-14 w-full max-w-[1920px]"
      >
        <CardLayoutFold
          nbCols={columns === '1' ? 1 : 2}
          haveThreeCards={havethreecards}
          title={
            title && (
              <Heading as="h2" variant="h2" css={[tw`text-[theme('colors.tertiary.5')] font-semibold pb-4`]}>
                {title}
              </Heading>
            )
          }
          leading={content && <WysiwygBlock variant="leading" as="div" content={content} />}
          cards={cards?.map(
            (card, index): React.ReactNode => (
              /* eslint-disable react/no-array-index-key */
              <PageLinkCard
                key={`card-${card?.title}-${index}`}
                title={card?.title ?? ''}
                text={card?.content ?? ''}
                permalink={card?.link?.url ? extractRelativePath(card.link.url) : ''}
                ctaText={cardsCtaText}
                ariaLabel={cardsAriaLabel}
              />
            ),
          )}
        />
      </Inline>
    </Cover>
  )
}

export default CardLayout
