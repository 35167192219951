/* eslint-disable react/display-name */
import { Icon, useSidePanel } from '@okam/shared-ui'
import type { RefObject } from 'react'
import React from 'react'
import { useButton } from 'react-aria'
import tw from 'twin.macro'
import Heading from '../../Heading'
import type { TCardContainerProps, TModalCTACardProps } from './interface'
import { getButtonStyle, getCardStyle } from './styles'

const CardContainer = React.forwardRef((props: TCardContainerProps, forwardRef: React.Ref<HTMLButtonElement>) => {
  const { children, colorTheme, ...rest } = props
  const ref = forwardRef
  const { buttonProps } = useButton({ ...props }, (forwardRef as RefObject<HTMLElement>) ?? ref)

  return (
    <button type="button" css={getCardStyle(colorTheme)} {...buttonProps} {...rest} ref={ref}>
      {children}
    </button>
  )
})

const ModalCTACard = (props: TModalCTACardProps) => {
  const { title, text, ariaLabel, colorTheme, ...rest } = props
  const { buttonProps } = useSidePanel()

  return (
    <CardContainer
      tw="text-left focus:outline-0 py-3"
      {...buttonProps?.openButtonProps}
      {...rest}
      ref={buttonProps?.openButtonRef}
      aria-label={ariaLabel}
      colorTheme={colorTheme}
    >
      <Heading variant="h5" as="h3" tw="pb-3 border-b mb-5 transition">
        {title}
      </Heading>
      <div tw="mb-4">{text}</div>
      <div
        css={[
          tw`min-h-12 min-w-12 rounded-full flex items-center justify-center transition duration-75`,
          getButtonStyle(colorTheme),
        ]}
        tw="w-max border-none opacity-100"
      >
        <Icon icon="LinePlus" />
      </div>
    </CardContainer>
  )
}

export default ModalCTACard
