import Link from 'next/link'
import BrandButton from '../../BrandButton'
import Heading from '../../Heading'
import type { TTeamMemberCardProps } from './interface'
import { hoverStyles } from './styles'

const TeamMemberCard = (props: TTeamMemberCardProps) => {
  const { children, permalink, email, name, phone } = props

  return (
    <div tw="max-w-[35rem]">
      <div css={hoverStyles} tw="overflow-hidden">
        <Link href={permalink} passHref aria-label={`Go to ${name}'s bio`}>
          <div tw="[aspect-ratio:306/419] relative">{children}</div>
        </Link>
      </div>
      <div tw="flex flex-col xs:flex-row items-start xs:items-center gap-2 xs:gap-0 mt-2 xs:mt-5">
        {email && (
          <BrandButton
            as="a"
            href={`mailto:${email}`}
            buttonStyle="default"
            icon="Mail"
            variant="primary"
            aria-label={`Send email to ${name}`}
            tw="!px-0"
          />
        )}
        <div tw="ml-3 w-full">
          {name && (
            <Heading
              variant="h6"
              as="h3"
              tw="w-full font-semibold uppercase text-black transition duration-300 ease-in-out"
            >
              {name}
            </Heading>
          )}
          {phone && (
            <Link
              href={`tel:${phone}`}
              aria-label={`Call ${name}`}
              tw="w-full mt-1 sm:text-lg text-xs text-tertiary-4 font-semibold transition duration-300 ease-in-out focus-visible:outline-primary-2"
            >
              T. {phone}
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default TeamMemberCard
