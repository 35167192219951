import tw, { css } from 'twin.macro'

const hoverStyles = css`
  &:hover > h3,
  &:active > h3 {
    ${tw`text-primary-1`}
  }

  &:hover > p:last-of-type,
  &:active > p:last-of-type {
    ${tw`text-primary-1`}

    span {
      ${tw`border-b-primary-1`}
    }
  }
`

export { hoverStyles }
