import { MediaText } from '@okam/brand-ui'
import { extractRelativePath } from '@okam/html2react/index'
import type { TMediatextLayout } from '../types'
import { theme } from 'twin.macro' /* eslint-disable-line */

type TMediaTextImgPos = 'right' | 'left' | undefined

const MediaTextLayout = (props: TMediatextLayout) => {
  const image = props?.image?.sourceUrl
  const colorTheme = [theme('colors.tertiary[6]'), theme('colors.white')]
  const imageAlt = props?.image?.altText
  const imagePosition = props?.imagePosition as TMediaTextImgPos
  const title = props?.title
  const text = props?.content
  const linkTitle = props?.link?.title
  const linkUrl = props?.link?.url ? extractRelativePath(props?.link?.url) : ''

  return (
    <MediaText
      image={image ?? ''}
      colorTheme={colorTheme}
      imageAlt={imageAlt ?? ''}
      imagePosition={imagePosition}
      title={title ?? ''}
      text={text ?? ''}
      linkTitle={linkTitle ?? ''}
      linkUrl={linkUrl}
      textBackgroundPattern="angles"
    />
  )
}

export default MediaTextLayout
