import { css } from 'twin.macro'
import { AnglesCSS } from '../illustrations/Angles'
import { ArchCSS } from '../illustrations/Arch'
import { BranchesCSS } from '../illustrations/Branches'
import { GearCSS } from '../illustrations/Gear'
import { LaurelCSS } from '../illustrations/Laurel'
import type { TPatternVariants } from './interface'

const bgPatterns = {
  branches: css`
    &::after {
      ${BranchesCSS}
    }
  `,
  laurel: css`
    &::after {
      ${LaurelCSS}
    }
  `,
  angles: css`
    &::after {
      ${AnglesCSS}
    }
  `,
  gear: css`
    &::after {
      ${GearCSS}
    }
  `,
  arch: css`
    &::after {
      ${ArchCSS}
    }
  `,
}

export const backgroundPatternStyles = css`
  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    z-index: 0;
  }
`

export const getBgPattern = (pattern: TPatternVariants) => {
  return bgPatterns[pattern]
}

export const setColorTheme = (colorTheme: string[]) => {
  return css`
    background-color: ${colorTheme[0]};
    color: ${colorTheme[1]};
  `
}
