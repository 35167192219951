/* eslint-disable no-underscore-dangle */
import type { LastPostsQuery, AllMembersFragment, Maybe } from 'gql/graphql'
import BaseLayout from '../BaseLayout/BaseLayout'
import CardLayout from '../CardLayout/CardLayout'
import ContactLayout from '../ContactLayout/ContactLayout'
import LogoBannerLayout from '../LogoBannerLayout/LogoBannerLayout'
import MediaTextLayout from '../MediaTextLayout/MediaTextLayout'
import ModalCardsLayout from '../ModalCardsLayout/ModalCardsLayout'
import NewsLayout from '../NewsLayout/NewsLayout'
import ProfessionalLayout from '../ProfessionalLayout/ProfessionalLayout'
import TeamSearchLayout from '../TeamSearchLayout/TeamSearchLayout'
import type { TFlexibleContent, TNewsLayout } from '../types'

interface TFlexibleContentDispatcher {
  content: TFlexibleContent
  allMembers?: Maybe<AllMembersFragment>
}

const FlexibleContentDispatcher = ({ content, allMembers }: TFlexibleContentDispatcher) => {
  const type = content?.__typename
  if (type == null || content == null) {
    return null
  }

  switch (type) {
    case 'Expertise_Flexiblecontent_OkamContent_BaseLayout':
    case 'Page_Flexiblecontent_OkamContent_BaseLayout':
      return <BaseLayout {...content} />

    case 'Expertise_Flexiblecontent_OkamContent_CardLayout':
    case 'Page_Flexiblecontent_OkamContent_CardLayout':
      return <CardLayout {...content} />

    case 'Expertise_Flexiblecontent_OkamContent_ProfessionalLayout':
    case 'Page_Flexiblecontent_OkamContent_ProfessionalLayout':
      return <ProfessionalLayout {...content} />

    case 'Expertise_Flexiblecontent_OkamContent_NewsLayout':
    case 'Page_Flexiblecontent_OkamContent_NewsLayout':
      return <NewsLayout {...(content as TNewsLayout & Maybe<LastPostsQuery>)} />

    case 'Expertise_Flexiblecontent_OkamContent_TeamSearchLayout':
    case 'Page_Flexiblecontent_OkamContent_TeamSearchLayout':
      return <TeamSearchLayout members={allMembers} props={content} />

    case 'Expertise_Flexiblecontent_OkamContent_LogoBannerLayout':
    case 'Page_Flexiblecontent_OkamContent_LogoBannerLayout':
      return <LogoBannerLayout {...content} />

    case 'Expertise_Flexiblecontent_OkamContent_ModalCardLayout':
    case 'Page_Flexiblecontent_OkamContent_ModalCardLayout':
      return <ModalCardsLayout {...content} />

    case 'Expertise_Flexiblecontent_OkamContent_ContactLayout':
    case 'Page_Flexiblecontent_OkamContent_ContactLayout':
      return <ContactLayout {...content} />

    case 'Expertise_Flexiblecontent_OkamContent_MediatextLayout':
    case 'Page_Flexiblecontent_OkamContent_MediatextLayout':
      return <MediaTextLayout {...content} />

    default:
      return null
  }
}

export default FlexibleContentDispatcher
