import { Cover, Inline } from '@bedrock-layout/primitives'
import tw, { css } from 'twin.macro'
import BrandButton from '../BrandButton'
import Heading from '../Heading'
import Img from '../Img'
import WysiwygBlock from '../WysiwygBlock'
import type { TMediaTextProps } from './interface'
import { setColorTheme, getBgPattern, backgroundPatternStyles } from './styles'

const MediaText = (props: TMediaTextProps) => {
  const {
    image,
    imageAlt,
    imagePosition = 'left',
    title,
    text,
    linkTitle,
    linkUrl,
    textBackgroundPattern,
    colorTheme = ['#10163B', '#fff'],
    ...rest
  } = props

  return (
    <Cover
      minHeight="fit-content"
      {...rest}
      css={[
        imagePosition === 'right' &&
          css`
            &::after {
              transform: rotateY(180deg);
            }
          `,
        textBackgroundPattern && getBgPattern(textBackgroundPattern),
        tw`relative`,
        setColorTheme(colorTheme),
        backgroundPatternStyles,
      ]}
    >
      <Inline tw="mx-auto max-w-[1920px]" justify="center">
        <div tw="grid md:grid-cols-2 z-10 w-full">
          <div css={[imagePosition === 'right' && tw`md:order-2`, tw`relative h-full aspect-square md:aspect-auto`]}>
            <Img
              src={image}
              alt={imageAlt}
              tw="object-cover w-full h-full"
              width={960}
              height={647}
              sizes="(max-width: 768px) 100vw,
              (max-width: 1920px) 50vw,
              1000px"
            />
          </div>
          <div
            css={[
              imagePosition === 'right' && tw`md:order-1`,
              tw`px-6 py-20 md:px-12 md:py-32 lg:px-14 2xl:px-32 2xl:py-48`,
            ]}
          >
            <div tw="grid content-center gap-4 h-full">
              {title && (
                <Heading variant="h3" as="h2" tw="font-semibold">
                  {title}
                </Heading>
              )}
              {text && (
                <div>
                  <WysiwygBlock content={text} />
                </div>
              )}
              {linkUrl && linkTitle && (
                <div>
                  <BrandButton
                    href={linkUrl}
                    as="a"
                    buttonStyle="outline"
                    variant="white"
                    tw="!px-0 mt-4 !justify-start"
                  >
                    {linkTitle}
                  </BrandButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </Inline>
    </Cover>
  )
}

export default MediaText
