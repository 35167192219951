import { Cover, Inline } from '@bedrock-layout/primitives'
import { TextColumns, WysiwygBlock, Heading } from '@okam/brand-ui/index'
import tw from 'twin.macro'
import type { TwStyle } from 'twin.macro'
import type { TBaseLayout } from '../types'

type TStyle = {
  background: TwStyle
  text: TwStyle
  title: TwStyle
}

const styles: Record<string, TStyle> = {
  style1: {
    background: tw`bg-white`,
    text: tw`text-black`,
    title: tw`text-tertiary-5`,
  },
  style2: {
    background: tw`bg-tertiary-2`,
    text: tw`text-black`,
    title: tw`text-tertiary-5`,
  },
  style3: {
    background: tw`bg-secondary-2`,
    text: tw`text-white`,
    title: tw`text-white`,
  },
}

const BaseLayout = (props: TBaseLayout) => {
  const { title, content, classname, style, columns } = props

  return (
    <Cover className={classname ?? ''} minHeight="fit-content" css={[styles?.[style ?? 'style1']?.background]}>
      <Inline
        justify="center"
        tw="flex flex-col h-full pt-24 pb-10 lg:pt-28 lg:pb-16 xl:pt-32 px-6 mx-auto md:px-12 lg:px-14 w-full max-w-[1920px]"
      >
        <TextColumns
          nbCols={Number(columns)}
          title={
            <Heading as="h2" variant="h2" css={[styles?.[style ?? 'style1']?.title]}>
              {title}
            </Heading>
          }
        >
          {content && (
            <div tw="!prose !prose-woods" css={[styles?.[style ?? 'style1']?.text]}>
              <WysiwygBlock content={content} />
            </div>
          )}
        </TextColumns>
      </Inline>
    </Cover>
  )
}

export default BaseLayout
