import Link from 'next/link'
import BrandButton from '../../BrandButton'
import Heading from '../../Heading'
import WysiwygBlock from '../../WysiwygBlock'
import type { TPageLinkCardProps } from './interface'
import { hoverStyles } from './styles'

const PageLinkCard = (props: TPageLinkCardProps) => {
  const { title, permalink, text = '', ctaText, ariaLabel } = props

  return (
    <Link href={permalink} aria-label={ariaLabel} passHref tw="focus:!outline-none focus-visible:shadow-focus">
      <div css={hoverStyles} tw="bg-transparent py-3">
        <Heading variant="h4" as="h3" tw="w-full pb-3 border-b border-tertiary-3 transition mb-5">
          {title}
        </Heading>
        {text && (
          <div tw="mb-8">
            <WysiwygBlock variant="p" content={text} />
          </div>
        )}
        <BrandButton
          as="p"
          tabIndex={-1}
          aria-hidden="true"
          buttonStyle="outline"
          variant="black"
          tw="!p-0 !justify-start !min-h-min !h-full"
          role="presentation"
        >
          {ctaText}
        </BrandButton>
      </div>
    </Link>
  )
}

export default PageLinkCard
