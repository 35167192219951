import tw, { css } from 'twin.macro'

export const overlayStyles = css`
  &::before,
  &::after {
    ${tw`absolute w-full h-[15%] z-10 opacity-80 pointer-events-none`}
    content: '';
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.88) 20.31%,
      rgba(255, 255, 255, 0.69) 45.83%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &::before {
    ${tw`top-0`}
  }

  &::after {
    ${tw`bottom-0 rotate-180`}
  }
`
