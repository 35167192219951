import tw from 'twin.macro'
import Heading from '../../Heading'
import WysiwygBlock from '../../WysiwygBlock'
import type { TContactCardProps } from './interface'

const getCardVariant = (variant: TContactCardProps['cardStyle']) => {
  if (variant === 'dark') return tw`bg-tertiary-4 text-white`
  return tw`text-tertiary-5 bg-white`
}

const ContactCard = (props: TContactCardProps) => {
  const { title, subtitle, cardStyle, content: leftCol, content2: rightCol } = props

  return (
    <div css={[tw`p-10 w-full`, getCardVariant(cardStyle)]}>
      <header tw="border-b border-tertiary-2 pb-4">
        <Heading as="h3" variant="h4" tw="font-semibold pb-2">
          {title}
        </Heading>
        {subtitle && (
          <Heading as="h4" variant="h6" tw="font-semibold uppercase">
            {subtitle}
          </Heading>
        )}
      </header>
      {(leftCol || rightCol) && (
        <div tw="pt-4 flex flex-col gap-4 lg:gap-8 lg:flex-row lg:justify-between lg:items-end">
          {leftCol && (
            <div>
              <WysiwygBlock content={leftCol} />
            </div>
          )}

          {rightCol && (
            <div>
              <WysiwygBlock content={rightCol} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ContactCard
