import { Columns } from '@bedrock-layout/primitives'
import { useEffect, useRef, useState } from 'react'
import tw from 'twin.macro'
import type { TCardsColumnsProps } from './interface'

const CardsColumns = (props: TCardsColumnsProps) => {
  const { nbCols, children, switchToOneCol = true, align = 'start' } = props

  const [newNbCols, setNewNbCols] = useState(nbCols)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const cols = ref?.current
    if (!cols) return undefined

    const observer = new ResizeObserver(() => {
      if (nbCols === 4) {
        if (window.innerWidth < 1024) setNewNbCols(2)
        else setNewNbCols(4)
      }
    })

    observer.observe(cols)
    return () => {
      observer.disconnect()
    }
  }, [nbCols])

  const setSwitchAt = (cols: number) => {
    if (cols === 4) {
      return '640px'
    }
    return '400px'
  }

  const getAlignment = (alignment: string) => {
    switch (alignment) {
      case 'start':
        return tw`!items-start`
      case 'center':
        return tw`!items-center`
      case 'end':
        return tw`!items-end`
      default:
        return ''
    }
  }

  return (
    <Columns
      ref={ref}
      columns={newNbCols}
      gutter="size5"
      switchAt={switchToOneCol ? setSwitchAt(newNbCols) : '0px'}
      css={getAlignment(align)}
    >
      {children}
    </Columns>
  )
}

export default CardsColumns
