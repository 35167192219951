/* eslint-disable no-unused-vars */
import { LogoBanner, Heading, BrandButton, Img, FullWidthTextSection } from '@okam/brand-ui'
import { extractRelativePath } from '@okam/html2react/index'
import React from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import colors from 'tailwindcss/colors'
import { theme } from 'twin.macro'
import type { TLogoBannerLayout } from '../types'

const LogoBannerLayout = (props: TLogoBannerLayout) => {
  const { link, logosGallery, title } = props
  return (
    <>
      <FullWidthTextSection
        colorTheme={theme('colors.secondary[2]')}
        minHeight="fit-content"
        xAlign="left"
        yAlign="center"
        bgPattern="laurel"
        patternPosition="right"
      >
        <div tw="!py-24 lg:!py-28 xl:!py-32">
          {title && (
            <Heading as="h2" variant="h2" tw="[color: theme(colors.white)] mb-8">
              {title}
            </Heading>
          )}
          {link?.title && link?.url && (
            <BrandButton
              variant="white"
              buttonStyle="hollow"
              icon="ArrowRight"
              as="a"
              tw="!w-fit !p-0"
              href={link?.url ? extractRelativePath(link.url) : ''}
            >
              {link?.title}
            </BrandButton>
          )}
        </div>
      </FullWidthTextSection>
      {logosGallery && (
        <LogoBanner>
          {logosGallery?.map((logo) => {
            return (
              <div key={logo?.id} tw="py-4 flex items-center">
                <Img src={logo?.sourceUrl ?? ''} alt={logo?.altText ?? ''} width="100" height="100" tw="m-auto" />
              </div>
            )
          })}
        </LogoBanner>
      )}
    </>
  )
}

export default LogoBannerLayout
