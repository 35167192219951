import { useDateFormatter } from '@react-aria/i18n'
import { isEmpty } from 'lodash'
import { useIsSSR } from 'react-aria'
import { styled } from 'twin.macro'
import typographyStyle from '../Typography/styles'
import type { TDateProps } from './interface'

const StyledTime = styled.time(typographyStyle)

const DateComponent = (props: TDateProps) => {
  const { date, dateFormat = 'long', variant = 'p' } = props
  const isSSR = useIsSSR()

  const parsedDate = new Date(date)

  const formatter = useDateFormatter({
    month: dateFormat,
    day: 'numeric',
    year: 'numeric',
  })

  const formattedDate = formatter.format(parsedDate)

  return isSSR || isEmpty(formattedDate) ? null : (
    <StyledTime variant={variant} as="time" dateTime={parsedDate.toISOString()} {...props}>
      {formattedDate}
    </StyledTime>
  )
}

export default DateComponent
