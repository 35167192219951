/* eslint-disable no-unused-vars */
import { Inline } from '@bedrock-layout/primitives'
import { ArticleCard, BrandButton, CardsColumns, Heading } from '@okam/brand-ui'
import { extractRelativePath } from '@okam/html2react/index'
import { useTranslation } from 'next-i18next'
import React from 'react'
import tw from 'twin.macro'
import type { LastPostsQuery, Maybe } from 'gql/graphql'
import { QUERY_LATEST_NEWS_COUNT } from '../../../../const'
import type { TNewsLayout } from '../types'

const NewsLayout = (props: TNewsLayout & Maybe<LastPostsQuery>) => {
  const { posts, title, link } = props
  const { t } = useTranslation('common')

  return (
    <Inline tw="block pt-24 pb-10 lg:pt-28 lg:pb-16 xl:pt-32 px-6 mx-auto md:px-12 lg:px-14 w-full max-w-[1920px]">
      <div tw="flex flex-col md:flex-row items-start md:justify-between mb-8 md:mb-12">
        {title && (
          <Heading variant="h2" as="h2" tw="font-semibold text-tertiary-5">
            {title}
          </Heading>
        )}
        {link?.url && (
          <BrandButton
            href={extractRelativePath(link.url)}
            as="a"
            buttonStyle="outline"
            variant="black"
            css={[tw`!px-0`]}
          >
            {link?.title}
          </BrandButton>
        )}
      </div>
      <CardsColumns nbCols={QUERY_LATEST_NEWS_COUNT} align="start">
        {posts?.nodes?.map((article) => {
          const uri = extractRelativePath(article?.uri ?? '')
          return (
            <ArticleCard
              key={`newscard-${article.title}-${article.date}`}
              label={article.categories?.nodes[0].name ?? ''}
              permalink={uri}
              title={article.title ?? ''}
              date={article.date ?? ''}
              ariaLabel={t('ARIA.GO_TO', { page: article.title })}
            />
          )
        })}
      </CardsColumns>
    </Inline>
  )
}

export default NewsLayout
