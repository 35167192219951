import tw, { css } from 'twin.macro'

export const activeStyles = css`
  &:active {
    div:first-of-type {
      ${tw`!bg-tertiary-4 !border-tertiary-4`}
    }
  }
`
export const hoverStyles = css`
  &:hover {
    div:first-of-type {
      ${tw`bg-tertiary-5 border-tertiary-5 text-white`}
    }
    p:first-of-type {
      ${tw`text-primary-1`}
    }
  }
`
