import CardsColumns from '../CardsColumns'
import TextColumns from '../TextColumns'
import type { TCardLayoutFold } from './interface'

const CardLayoutFold = (props: TCardLayoutFold) => {
  const { title, leading, nbCols, cards, haveThreeCards } = props
  const oneCol = haveThreeCards === true ? 3 : 4
  return (
    <div tw="w-full">
      <TextColumns nbCols={nbCols} title={title} haveThreeCards={haveThreeCards}>
        {leading}
        <div tw="mt-8">
          <CardsColumns nbCols={nbCols === 1 ? oneCol : nbCols} switchToOneCol align="start">
            {cards}
          </CardsColumns>
        </div>
      </TextColumns>
    </div>
  )
}
export default CardLayoutFold
