import { Cover, Inline, Grid } from '@bedrock-layout/primitives'
import { Heading, WysiwygBlock, ContactCard, TextColumns } from '@okam/brand-ui'
import type { TContactLayout } from '../types'

const ContactLayout = (props: TContactLayout) => {
  const { title, content, cards } = props
  return (
    <Cover tw="bg-tertiary-2" minHeight="fit-content">
      <Inline tw="pt-24 pb-10 lg:pt-28 lg:pb-16 xl:pt-32 px-6 mx-auto md:px-12 lg:px-14 w-full max-w-[1920px]">
        <TextColumns
          nbCols={2}
          title={
            title && (
              <Heading as="h2" variant="h2" tw="text-tertiary-5">
                {title}
              </Heading>
            )
          }
        >
          <Grid gutter="size6">
            {content && <WysiwygBlock content={content} />}
            {cards && cards.map((card) => <ContactCard {...card} key={`contactLayout-contactcard-${card?.title}`} />)}
          </Grid>
        </TextColumns>
      </Inline>
    </Cover>
  )
}

export default ContactLayout
