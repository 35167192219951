import { Column, Columns, useResizeObserver, useStatefulRef } from '@bedrock-layout/primitives'
import { useState } from 'react'
import type { TTextColumnsProps } from './interface'

const TextColumns = (props: TTextColumnsProps) => {
  const { title, nbCols, haveThreeCards, children } = props

  const [columnsProps, setColumnsProps] = useState({
    oneCol: {
      nbCols,
      col1Span: 0,
      col1OffsetEnd: 0,
    },
    twoCols: {
      nbCols,
      col1Span: 0,
      col1OffsetEnd: 0,
      col2Span: 0,
      col2OffsetEnd: 0,
    },
  })

  const ref = useStatefulRef<HTMLDivElement>()

  const cols = ref?.current

  useResizeObserver(() => {
    if (cols.offsetWidth > 1024) {
      setColumnsProps({
        ...columnsProps,
        oneCol: {
          nbCols: 12,
          col1Span: 12,
          col1OffsetEnd: 0,
        },
        twoCols: {
          nbCols: 12,
          col1Span: 3,
          col2Span: 6,
          col1OffsetEnd: 1,
          col2OffsetEnd: 2,
        },
      })
    } else if (cols.offsetWidth <= 1024 && cols.offsetWidth > 768) {
      setColumnsProps({
        ...columnsProps,
        oneCol: {
          nbCols: 8,
          col1Span: 8,
          col1OffsetEnd: 0,
        },
        twoCols: {
          nbCols: 8,
          col1Span: 3,
          col2Span: 4,
          col1OffsetEnd: 0,
          col2OffsetEnd: 1,
        },
      })
    } else {
      setColumnsProps({
        ...columnsProps,
        oneCol: {
          nbCols: 1,
          col1Span: 1,
          col1OffsetEnd: 0,
        },
        twoCols: {
          nbCols: 1,
          col1Span: 1,
          col2Span: 1,
          col1OffsetEnd: 0,
          col2OffsetEnd: 0,
        },
      })
    }
  }, cols)

  if (nbCols === 1) {
    if (haveThreeCards === true) {
      return (
        <Columns ref={ref} columns={1} gutter="size5" tw="w-full items-start">
          <Column span={columnsProps.oneCol.col1Span} offsetEnd={columnsProps.oneCol.col1OffsetEnd}>
            {title}
            {children ?? null}
          </Column>
        </Columns>
      )
    }
    return (
      <Columns ref={ref} columns={columnsProps.oneCol.nbCols} gutter="size5" tw="w-full">
        <Column span={columnsProps.oneCol.col1Span} offsetEnd={columnsProps.oneCol.col1OffsetEnd}>
          {title}
          {children ?? null}
        </Column>
      </Columns>
    )
  }
  return (
    <Columns ref={ref} columns={columnsProps.twoCols.nbCols} gutter="size5" tw="w-full">
      <Column span={columnsProps.twoCols.col1Span} offsetEnd={columnsProps.twoCols.col1OffsetEnd}>
        {title}
      </Column>
      <Column span={columnsProps.twoCols.col2Span} offsetEnd={columnsProps.twoCols.col2OffsetEnd}>
        {children}
      </Column>
    </Columns>
  )
}

export default TextColumns
