/* eslint-disable no-underscore-dangle */
import { Cover, Inline } from '@bedrock-layout/primitives'
import { Heading, WysiwygBlock, TeamMemberCard, Img, BrandButton } from '@okam/brand-ui'
import { extractRelativePath } from '@okam/html2react'
import { useIsClient } from '@okam/shared-ui'
import Link from 'next/link'
import type { TwStyle } from 'twin.macro'
import tw from 'twin.macro'
import type { Member, Maybe, Expertise_Expertisefields_ExpertiseMembers } from 'gql/graphql'
import type { TProfessionalLayout } from '../types'

const styles: Record<string, TwStyle> = {
  style1: tw`bg-white`,
  style2: tw`bg-tertiary-1`,
}

const ProfessionalLayout = (
  props: TProfessionalLayout & { expertiseMembers?: Expertise_Expertisefields_ExpertiseMembers[] },
) => {
  const { title, content, classname, style, link, __typename: typename } = props
  const expertiseMembers = props?.expertiseMembers
  const professionalMembers =
    typename === 'Expertise_Flexiblecontent_OkamContent_ProfessionalLayout' ||
    typename === 'Page_Flexiblecontent_OkamContent_ProfessionalLayout'
      ? props?.members
      : []
  const getImage = (member?: Maybe<Member>) => member?.featuredImage?.node
  const { isClient } = useIsClient()

  const members = expertiseMembers || professionalMembers || []

  if (!members || !members?.length) return null

  return (
    <Cover className={classname ?? ''} minHeight="fit-content" css={[styles?.[style ?? 'style1']]}>
      <Inline
        justify="center"
        tw="flex flex-col h-full pt-24 pb-10 lg:pt-28 lg:pb-16 xl:pt-32 px-6 mx-auto md:px-12 lg:px-14 w-full max-w-[1920px]"
      >
        {(title || content) && (
          <div tw="pb-8 w-full">
            <div tw="flex flex-col pb-2 justify-between md:flex-row lg:items-center">
              {title && (
                <Heading as="h3" variant="h3" tw="grow text-tertiary-5 w-fit">
                  {title}
                </Heading>
              )}
              {isClient && link?.url && link?.title && (
                <BrandButton buttonStyle="outline" variant="black" tw="w-fit">
                  <Link href={link.url}>{link.title}</Link>
                </BrandButton>
              )}
            </div>
            {content && <WysiwygBlock content={content} />}
          </div>
        )}
        {isClient && (
          <div tw="w-full items-start grid grid-cols-2 gap-6 lg:grid-cols-4">
            {members?.map((member: Maybe<Member>, index: number) => {
              if (!member) return null

              return (
                <TeamMemberCard
                  email={member?.memberFields?.memberEmail ?? ''}
                  permalink={extractRelativePath(member?.uri ?? '') ?? ''}
                  name={member?.title ?? ''}
                  phone={member?.memberFields?.memberPhoneNumber ?? ''}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${typename}-member-${title}-${member?.title}-${index}`}
                >
                  <div style={{ height: '100%' }}>
                    {getImage(member) ? (
                      <Img
                        src={getImage(member)?.sourceUrl ?? ''}
                        alt={getImage(member)?.altText ?? ''}
                        width={getImage(member)?.mediaDetails?.width ?? undefined}
                        height={getImage(member)?.mediaDetails?.height ?? undefined}
                      />
                    ) : (
                      <div tw="bg-tertiary-2 h-full" />
                    )}
                  </div>
                </TeamMemberCard>
              )
            })}
          </div>
        )}
      </Inline>
    </Cover>
  )
}

export default ProfessionalLayout
