import DateComponent from '@okam/shared-ui/src/components/Date'
import Link from 'next/link'
import tw from 'twin.macro'
import Heading from '../../Heading'
import Paragraph from '../../Paragraph'
import Tag from '../../Tag'
import type { TArticleCardProps } from './interface'
import { activeStyles, hoverStyles } from './styles'

const ArticleCard = (props: TArticleCardProps) => {
  const { label, permalink, title, date, ariaLabel } = props

  return (
    <Link href={permalink} aria-label={ariaLabel} passHref tw="focus:outline-none focus-visible:shadow-focus">
      <div css={[tw`py-4`, activeStyles, hoverStyles]}>
        {label && <Tag tw="w-max">{label}</Tag>}
        <Heading variant="h5" as="p" tw="mt-6 mb-5 pb-3 border-b border-tertiary-2 text-black transition duration-300">
          {title}
        </Heading>
        <Paragraph tw="uppercase text-tertiary-4">
          <DateComponent date={date} dateFormat="long" />
        </Paragraph>
      </div>
    </Link>
  )
}

export default ArticleCard
