/* eslint-disable @typescript-eslint/naming-convention */
export const QUERY_TEAM_NUMBER_COUNT = 100
export const QUERY_EXPERTISE_NUMBER_COUNT = 100
export const QUERY_LATEST_NEWS_COUNT = 3
export const TEAM_ANIMATION_MEMBERS_COUNT = 20
export const TEAM_ANIMATION_MEMBERS_COL = 4
export const PROFESSIONAL_LAYOUT_MEMBERS_COUNT = 4
export const QUERY_NEWS_PER_PAGE = 12
export const SINGLE_MEMBER_IMAGE_WIDTH = 300
export const SINGLE_MEMBER_IMAGE_HEIGHT = 400
export const TEAM_LISTING_CARD_WIDTH = 435
export const TEAM_LISTING_CARD_HEIGHT = 600
export const MEMBERS_TYPE_DISPLAY = ['associe', 'avocat-conseil', 'partner', 'senior-counsel']
export const MEMBERS_PER_COL = 15
