import React from 'react'
import Div100vh from 'react-div-100vh'
import tw from 'twin.macro'
import { useSidePanel } from '../../providers/SidePanel'
import RenderWithSlide from '../../transitions/RenderWithSlide'
import SidePanelTransition from '../../transitions/SidePanelTransition'
import Modal from '../Modal'
import type { TSidePanelProps } from './interface'
import { Div100vhStyle } from './styles'

const SidePanel = (props: TSidePanelProps) => {
  const { children, containerStyle, closeBtnRender, innerDivStyle } = props

  const { overlayState } = useSidePanel()

  return (
    <Modal state={overlayState} transitionComponent={SidePanelTransition}>
      <Div100vh css={containerStyle ?? Div100vhStyle}>
        {closeBtnRender && closeBtnRender()}
        <RenderWithSlide isVisible={overlayState.isOpen}>
          <div css={tw`h-full w-full flex justify-end`}>
            <div css={innerDivStyle ?? tw`bg-black h-full w-full overflow-y-auto`}>{children}</div>
          </div>
        </RenderWithSlide>
      </Div100vh>
    </Modal>
  )
}

export default React.memo(SidePanel)
